const clearanceBannerUrl =
  "https://static.speedwaymotors.com/images/GS_SP_9Feb17.jpg";
const newProductsBannerUrl =
  "https://content.speedwaymotors.com/OtherImages/deals_1.png";
const newProductsHtml =
  "In today’s fast - paced automotive world, everyone continues to push the limits of style and function. <b>New parts</b> are continually emerging. Keeping up with the latest trends can be daunting. Here at Speedway Motors we strive to stay ahead of our competition with curated collections of <b>new parts</b> designed to exceed all your performance and appearance needs. From the SEMA show to PRI, the Wissota 100 to the Knoxville Nationals, Cruisin’ the Coast to Hot August Nights; we have all the hottest <b>new parts</b> for your latest project right here at Speedway Motors!";

const buildMiscFacetContentPages = (productResultPageData) => {
  
  if (!productResultPageData.selectedFacetOptions) {
    return;
  }
  if (
    productResultPageData.selectedFacetOptions.some((option) =>
      option.searchResponseFacetOptions.some(
        (facetOption) => facetOption.displayValue === "Clearance"
      )
    )
  ) {
    productResultPageData.pageContent = {
      ...productResultPageData.pageContent,
      bannerImageUrl: clearanceBannerUrl,
    };
    productResultPageData.displayTitle = "Clearance Deals";
  } else if (
    productResultPageData.selectedFacetOptions.some((option) =>
      option.searchResponseFacetOptions.some(
        (facetOption) => facetOption.displayValue === "Garage Sale"
      )
    )
  ) {
    productResultPageData.pageContent = {
      ...productResultPageData.pageContent,
      bannerImageUrl: newProductsBannerUrl,

    };
  } else if (
    productResultPageData.selectedFacetOptions.some((option) =>
      option.searchResponseFacetOptions.some(
        (facetOption) => facetOption.displayValue === "New Products"
      )
    )
  ) {
    productResultPageData.pageContent = {
      ...productResultPageData.pageContent,
      bannerImageUrl: newProductsBannerUrl,
      html: newProductsHtml
    };
  }
};

export default buildMiscFacetContentPages;
